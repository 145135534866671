import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; // Importa useHistory
import { Card, Row, Col } from 'antd';
import SubMenu from '../ExamplesConfig/SubMenu';
import listMenu from '../ExamplesConfig/constants/menuConfig'; // Para el menú de config

const Settings = () => {
  const [settingFormInfo, setSettingFormInfo] = useState("Configuración General");
  const history = useHistory(); // Inicializa el hook de navegación

  // Redirigir automáticamente a /settings/General al cargar
  useEffect(() => {
    history.push('/settings/general');
  }, [history]);

  const selectSetting = (settingSelected) => {
    console.log(settingSelected);
    switch (settingSelected) {
      case 'general': setSettingFormInfo("Configuración General");
        break;
      case 'lista de espera': setSettingFormInfo("Lista de espera");
        break;
      case 'reservaciones': setSettingFormInfo("Reservaciones");
        break;
      case 'campos de entrada': setSettingFormInfo("Campos de entrada");
        break;
      case 'etiquetas de entrada': setSettingFormInfo("Etiquetas de entrada");
        break;
      default: setSettingFormInfo("Configuración General");
        break;
    }
  };

  return (
    <div>
      {/* Menú lateral y contenido principal */}
      <Row>
        <Col xs={5}>
          <SubMenu settingsList={listMenu} />
        </Col>
        <Col xs={19} className='pl-2'>
          <Card className='bg-white rounded shadow-none'>
            <h2 className='fw-bold'>{settingFormInfo}</h2>
          </Card>
          <Card className='bg-white rounded shadow-none'>
            Aquí iría el contenido dinámico.
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Settings;
