import { useState, useEffect } from 'react'
import { PermissionService } from 'src/services'
import { constants, dialogConfirm, alerts, utils } from 'src/utils'

const usePermission = () => {
    
    const [loading, setLoading] = useState(false)
    const [tableState, setTableState] = useState({
        data: [],
        pagination: {
            pageSize: 10,
            current: 1, 
            total: 0,
        },
        filter: {},
        sorting: {},
    })

    const handleTableChange = async (pagination, filters, sorter) => {

        let dataToSearch = {
            sorting: {},
            filter: utils.firstValueOnObjectWithArray(filters),
            pagination,
        }

        if(sorter.column){
            dataToSearch.sorting[sorter.field] = sorter.order === 'ascend' ? 'asc' : ( sorter.order === 'descend' ? 'desc' : '')
        }
        
        getData(dataToSearch);
    }

    const refreshTable = () => {
        getData({
            ...tableState,
        })
    }

    const getData = async (params = {}) => {

        setLoading(true)
        try{

            let dataToSend = {
                page   : params.pagination.current || 1,
                count  : params.pagination.pageSize || 10,
                sorting: params.sorting || undefined,
                filter : params.filter || undefined,
            }

            let response = await PermissionService.getAll(dataToSend)

            if(response.status === constants.response.success){
                setTableState({
                    data: response.data.rows,
                    pagination: {
                        ...params.pagination,
                        total: response.data.count,
                    },
                    sorting: params.sorting,
                    filter: params.filter,
                })
            }else{
                alerts.warn( response.message )
            }
        }catch(error){
            alerts.error( error.message )
        }

        setLoading(false)

    }

    const deletePermission = (permission) => {

        dialogConfirm.confirmDelete({
            title: `¿Esta seguro de eliminar el permiso ${permission.name}?`,
            onOk: () => {
                deletePermissionStep2(permission)
            },
            onCancel: () => {

            }
        })
    }

    const deletePermissionStep2 = async (permission) => {
        setLoading(true)
        try {
            
            let response = await PermissionService.delete(permission.id)
            if(response.status === constants.response.success){
                alerts.success("Se ha eliminado el permiso")
                refreshTable()
            }else{
                alerts.warn(response.message)
            }

        } catch (error) {
            alerts.error(error.message)
        }
        setLoading(false)
    }

    useEffect(() => {

        refreshTable()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
        loading,
        tableState,
        refreshTable,
        handleTableChange,
        getData,
        deletePermission
    }

}

export default usePermission;