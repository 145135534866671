import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PermissionWrapper from 'src/app/components/utils/permission-wrapper';
import { ScanOutlined } from '@ant-design/icons'; // Importar el ícono

export function CheckinFlotante() {
  return (
    <PermissionWrapper module="mdu_permission_user">
      <div className="sticky-toolbar nav flex-column p-3">
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="checkin-tooltip">Check-In</Tooltip>}
        >
          <Link
            to="/checkin"
            className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
            style={{
              position: 'fixed',
              bottom: '70px',
              right: '40px',
              zIndex: '1000',
              borderRadius: '50%',
              boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
              width: '40px', // Ancho del botón
              height: '40px', // Altura del botón
              fontSize: '24px', // Tamaño del icono o texto dentro del botón
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ScanOutlined /> {/* Reemplaza el contenido con el ícono */}
          </Link>
        </OverlayTrigger>
      </div>
    </PermissionWrapper>
  );
}
