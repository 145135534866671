import {useState, useEffect} from 'react'
import {utils, constants, dialogConfirm, alerts} from 'src/utils'
import {UserService} from 'src/services'

const useUserModule = () => {  

  const [loading, setLoading] = useState(false)
  const [formState,setFormState] = useState({ isOpen: false,data: null })
  const [tableState, setTableState] = useState({
    data: [],
    pagination: {
      pageSize: 10,
      current: 1, 
      total: 0,
    },
    filter: {},
    sorting: {},
  })

  const handleTableChange = async (pagination, filters, sorter) => {
    let dataToSearch = {
      sorting: {},
      filter: utils.firstValueOnObjectWithArray(filters),
      pagination,
    }

    if(sorter.column){
      dataToSearch.sorting[sorter.field] = sorter.order === 'ascend' ? 'asc' : ( sorter.order === 'descend' ? 'desc' : '')
    }
    
    getData(dataToSearch);
  }

  const refreshTable = () => {
    getData({
      ...tableState,
    })
  }

  const getData = async (params = {}) => {

    setLoading(true)
    try{

      let dataToSend = {
        page   : params.pagination.current || 1,
        count  : params.pagination.pageSize || 10,
        sorting: params.sorting || undefined,
        filter : params.filter || undefined,
      }

      let response = await UserService.getAllUsers(dataToSend)

      if(response.status === constants.response.success){
        setTableState({
          data: response.data.rows,
          pagination: {
              ...params.pagination,
              total: response.data.count,
          },
          sorting: params.sorting,
          filter: params.filter,
        })
      }else{
        alerts.warn( response.message )
      }
    }catch(error){
      alerts.error( error.message )
    }

    setLoading(false)

  }
  
  const toggleForm = (user) => {
    if (formState.isOpen) {
      setFormState({ isOpen: false, data: null })
    } else {
      setFormState({ isOpen: true, data: user })
    }
  }

  const deleteItem = (item) => {
  
    dialogConfirm.confirmDelete({
      title: `¿Esta seguro de eliminar el usuario ${item.first_name} ${item.last_name}?`,
      onOk: () => {
        deleteUser(item)
      },
      onCancel: () => {
  
      }
    })
  }

  const deleteUser = async (user) => {
    setLoading(true)
    try {

      let response = await UserService.deleteUser(user.id)
      if (response.status === constants.response.success) {
        alerts.success("El usuario ha sido eliminado")
        refreshTable()
      } else {
        alerts.warn(response.message)
      }

    } catch (error) {
      alerts.error(error.message)
    }
    setLoading(false)
  }

  const enabledItem = (item) => {
  
    dialogConfirm.confirmDelete({
      title: `¿Esta seguro de ${item.enabled ? 'Desactivar' : 'Activar'} el usuario ${item.first_name} ${item.last_name}?`,
      onOk: () => {
        enabledUser(item)
      },
      onCancel: () => {
  
      }
    })
  }

  const enabledUser = async (user) => {
    setLoading(true)
    try {

      let response = await UserService.enabledUser(user.id)
      if (response.status === constants.response.success) {
        alerts.success(`El usuario ha sido ${user.enabled==='active' ? 'Desactivado' : 'Activado'}`)
        refreshTable()
      } else {
        alerts.warn(response.message)
      }

    } catch (error) {
      alerts.error(error.message)
    }
    setLoading(false)
  }

  useEffect(() => {

      refreshTable()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
      loading,
      tableState,
      formState,
      refreshTable,
      handleTableChange,
      toggleForm,
      deleteItem,
      enabledItem
  }

}

export default useUserModule