import React from 'react'
import { Card, Table, Button, Space, Tooltip } from 'antd'
import { PlusOutlined, ReloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { utils } from 'src/utils'
import usePermission from './hook'
import { Link } from 'react-router-dom'
import PermissionWrapper from 'src/app/components/utils/permission-wrapper'
import routesMap from 'src/routesMap'

const SettingsPermissions = () => {
  const { loading, tableState, refreshTable, handleTableChange, deletePermission } = usePermission()

  return (
    <Card title="Permisos">
      {/* Botones */}
      <Space>
        <PermissionWrapper module="mdu_permission_user" create>
          <Link to={routesMap.newPermission.path}>
            <Button type="primary" icon={<PlusOutlined />}>
              Nuevo
            </Button>
          </Link>
        </PermissionWrapper>
        <Button type="default" onClick={() => refreshTable()} icon={<ReloadOutlined />}>
          Actualizar
        </Button>
      </Space>

      {/* Espaciado */}
      <br />
      <br />

      {/* Tabla */}
      <Table
        columns={[
          {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            width: '40%',
            ...utils.getColumnSearchProps('name'),
          },
          {
            title: 'Descripción',
            dataIndex: 'description',
            key: 'description',
            width: '40%',
          },
          {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            width: '20%',
            render: (text, value, index) => {
              return (
                <Space key={index}>
                  <PermissionWrapper module="mdu_permission_user" edit>
                    <Tooltip title="Editar">
                      <Link to={routesMap.editPermission.path.replace(':id', value.id)}>
                        <Button shape="circle" type="primary" icon={<EditOutlined />} />
                      </Link>
                    </Tooltip>
                  </PermissionWrapper>

                  <PermissionWrapper module="mdu_permission_user" delete>
                    <Tooltip title="Eliminar">
                      <Button
                        shape="circle"
                        type="default"
                        danger
                        onClick={() => deletePermission(value)}
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </PermissionWrapper>
                </Space>
              )
            },
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={tableState.data}
        pagination={tableState.pagination}
        loading={loading}
        scroll={{ x: 'auto' }}
        onChange={handleTableChange}
      />
    </Card>
  )
}

export default SettingsPermissions
