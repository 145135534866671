import { initializeApp,getApps } from "firebase/app";
import { getDatabase, ref, onChildAdded, onChildChanged, onChildRemoved, get, update } from "firebase/database";

// Tu configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBslsmFTcvBRsHG2wH8manRznx-10tMVgk",
  authDomain: "tappit-c66f1.firebaseapp.com",
  databaseURL: "https://tappit-c66f1-default-rtdb.firebaseio.com",
  projectId: "tappit-c66f1",
  storageBucket: "tappit-c66f1.firebasestorage.app",
  messagingSenderId: "1072871673798",
  appId: "1:1072871673798:web:bccb69eae27258583aacc7",
  measurementId: "G-W2VWERW9M8"
};

//paraque no exista conflicto entre proyectos
const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
const database = getDatabase(app);
// Inicializar Firebase
//const app = initializeApp(firebaseConfig);
//const database = getDatabase(app);

// Funciones de escucha de cambios en la base de datos
const listenToEvents = (callback) => {
  const eventosRef = ref(database, 'booking'); // Referencia a la ruta 'booking'

  onChildAdded(eventosRef, (snapshot) => {
    callback('added', snapshot);
  });

  onChildChanged(eventosRef, (snapshot) => {
    callback('changed', snapshot);
  });

  onChildRemoved(eventosRef, (snapshot) => {
    callback('removed', snapshot);
  });
};

// Función para obtener un ticket por su ID
const getTicketById = (bookingId) => {
  const ticketRef = ref(database, 'booking/' + bookingId); // Referencia al ticket específico
  return get(ticketRef).then(snapshot => {
    if (snapshot.exists()) {
      return snapshot.val(); // Devuelve los datos del ticket si existe
    } else {
      return null; // Si no existe, retorna null
    }
  });
};

// Función para actualizar el estado de "isNotified" de un ticket
const updateTicketStatus = (bookingId, updates) => {
  const ticketRef = ref(database, 'booking/' + bookingId); // Referencia al ticket específico
  return update(ticketRef, updates); // Actualiza los datos del ticket
};

// Exportar todo lo necesario
export default {
  app,
  database,
  listenToEvents,
  getTicketById,
  updateTicketStatus
};
