import React from 'react';
import { Space, Button, Table, Tooltip, Switch, Card } from 'antd';
import { PlusOutlined, ReloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import useUserModule from './usuario/hook';
import { utils } from 'src/utils';
import UserForm from './usuario/form/index';
import PermissionWrapper from 'src/app/components/utils/permission-wrapper';

const SettingsUsuario = () => {
  const {
    loading,
    tableState,
    formState,
    handleTableChange,
    refreshTable,
    toggleForm,
    deleteItem,
    enabledItem,
  } = useUserModule();

  return (
    <Card title="Usuarios">
      {/* Botones de acciones */}
      <Space>
        <PermissionWrapper module={'mdu_user'} create>
          <Button
            type="primary"
            loading={loading}
            onClick={() => toggleForm(null)}
            icon={<PlusOutlined />}
          >
            Nuevo
          </Button>
        </PermissionWrapper>
        <Button type="default" loading={loading} onClick={refreshTable} icon={<ReloadOutlined />}>
          Actualizar
        </Button>
      </Space>

      <br />
      <br />

      {/* Tabla de usuarios */}
      <Table
        bordered
        rowClassName={(item) => (item.is_first ? 'bg-secondary' : '')}
        columns={[
          {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            width: '20%',
            ...utils.getColumnSearchProps('name'),
            render: (text, value) => `${value.first_name || ''} ${value.last_name || ''}`,
          },
          {
            title: 'Correo electrónico',
            dataIndex: 'email',
            key: 'email',
            width: '15%',
          },
          {
            title: 'Nombre de Usuario',
            dataIndex: 'username',
            key: 'username',
            width: '15%',
          },
          {
            title: 'Permiso',
            dataIndex: 'permission',
            key: 'permission',
            width: '20%',
            render: (text, value) => (value.UserPermission ? value.UserPermission.name : ''),
          },
          {
            title: 'Activo',
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            render: (text, value) => (
              <Tooltip title={value.enabled ? 'Desactivar' : 'Activar'}>
                <Switch
                  disabled={value.is_first}
                  checkedChildren="Si"
                  unCheckedChildren="No"
                  checked={!!value.enabled}
                  loading={false}
                  onClick={() => enabledItem(value)}
                />
              </Tooltip>
            ),
          },
          {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            render: (text, value) =>
              !value.is_first && (
                <Space>
                  <PermissionWrapper module={'mdu_user'} edit>
                    <Tooltip title="Editar">
                      <Button
                        shape="circle"
                        type="primary"
                        onClick={() => toggleForm(value)}
                        icon={<EditOutlined />}
                      />
                    </Tooltip>
                  </PermissionWrapper>

                  <PermissionWrapper module={'mdu_user'} delete>
                    <Tooltip title="Eliminar">
                      <Button
                        shape="circle"
                        type="default"
                        danger
                        onClick={() => deleteItem(value)}
                        icon={<DeleteOutlined />}
                      />
                    </Tooltip>
                  </PermissionWrapper>
                </Space>
              ),
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={tableState.data}
        pagination={tableState.pagination}
        loading={loading}
        scroll={{ x: 'auto' }}
        onChange={handleTableChange}
      />

      {/* Formulario de usuario */}
      <UserForm
        isVisible={formState.isOpen}
        data={formState.data}
        onCancel={toggleForm}
        onSuccess={() => {
          toggleForm();
          refreshTable();
        }}
      />
    </Card>
  );
};

export default SettingsUsuario;
