import { useState, useEffect } from 'react'
import { Form } from 'antd'
import { constants, alerts } from 'src/utils'
import {UserService} from 'src/services'

let originalData = null

const useUserForm = (props) => {

  const [loading, setLoading] = useState(false)
  const [listPermissions, setListPermissions] = useState([])
  const [userSaved, setUserSaved] = useState({ success: false, data: null})
  const [form] = Form.useForm()

  const onSubmit = () => {
    form.validateFields().then(values => {
      if(originalData && originalData.id){
        updateUser(values)
      }else{
        saveUser(values)
      }
      
    })
    .catch(info => {
      console.log('Validate Failed:', info);
    });
  }

  const updateUser = async (dataToSend) => {
    setLoading(true)
    try {
      let response = await UserService.updateUSer(originalData.id, dataToSend)

      if(response.status === constants.response.success){
        alerts.success("El usuario ha sido actualizado")
        setUserSaved({ success: true, data: response.data})
      }else{
        alerts.warn(response.message)
      }
      
    } catch (error) {
      alerts.error(error.message)
    }
    setLoading(false)
  }

  const saveUser = async (dataToSend) => {
  
    setLoading(true)
    try {

      let response = await UserService.createUser(dataToSend)

      if(response.status === constants.response.success){
        alerts.success("El usuario ha sido creado")
        setUserSaved({ success: true, data: response.data})
      }else{
        alerts.warn(response.message)
      }
      
    } catch (error) {
      alerts.error(error.message)
    }
    setLoading(false)
  }

  const setInitialData = (data) => {
    originalData = data
    form.setFieldsValue({
      first_name : data ? data.first_name : '',
      last_name  : data ? data.last_name  : '',
      username   : data ? data.username   : '',
      email      : data ? data.email      : '',
      user_permission_id : data&&data.user_permission_id ? parseInt(data.user_permission_id) : undefined,
      password   : data && data.id ? ''   : undefined,
    })    

    getListPermission();
  }

  const afterCloseForm = () => {
    form.resetFields()
    setUserSaved({success: false, data: null})
    setLoading(false)
  }

  const getListPermission = async () => {
    try {

      let response = await UserService.getPermissionsList()
      
      if(response.status === constants.response.success){
        setListPermissions(response.data || [])
      }else{
        alerts.warn(response.message)
      }
      
    } catch (error) {
      alerts.error(error.message)
    }
  }

  useEffect(() => {
    getListPermission()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { loading, form, userSaved, listPermissions, onSubmit, setInitialData, afterCloseForm }

}

export default useUserForm