import api from 'src/services/api'

const BookingService = {
  getAll: (businessId, data) => api.get('/v1/provider/business/'+businessId+'/booking', data),
  getAll2: (businessId, data) => api.get('/v1/provider/business/'+businessId, data),
  // create: (data) => api.post('/v1/provider/business', data),
  getOne: (businessId, bookingId) => api.get('/v1/provider/business/'+businessId+'/booking/'+ bookingId),
  // update: (id, data) => api.put('/v1/provider/business/'+id, data),
  // delete: (id) => api.delete('/v1/provider/business/'+id),
  // updateEnabled: (id) => api.put('/v1/provider/business/'+id+'/enabled'),
  updateStatus: (businessId, bookingId, data) => api.put('/v1/provider/business/'+businessId+'/booking/'+bookingId+'/status', data),
  // /v1/provider/business/3/booking/4/status
  getUserList: () => api.get('/v1/provider/business/user-list'),
  getAllUsers: (businessId, data) => api.get('/v1/provider/business/'+businessId+'/user', data),
  createUser: (businessId, data) => api.post('/v1/provider/business/'+businessId+'/user', data),
  deleteUser: (businessId, userId) => api.delete('/v1/provider/business/'+businessId+'/user/'+userId,),
  getFreeResources: (businessId, data) => api.get('/v1/provider/business/'+businessId+'/resources/freeresources', data),
  // getAllReources: (businessId, data) => api.get('/v1/provider/business/'+businessId+'/resources', data),
  getAllResources: (businessId, data) => api.get(`/v1/provider/business/${businessId}/resources`, data),
  getAllReources: (businessId, data) => api.get(`/v1/provider/business/${businessId}/resources`, data),
  updateResource: (businessId, resourceId, data) => 
    api.put(`/v1/provider/business/${businessId}/resources/${resourceId}`, data),
  deleteResource: (businessId, resourceId) => 
    api.delete(`/v1/provider/business/${businessId}/resources/${resourceId}`),
  postMassiveResource: (businessId, data) =>
    api.post(`/v1/provider/business/${businessId}/massive_resource/`, data),
  
  updateStatusresourse: (businessId, bookingId, resourceId, data) => 
    api.put(`/v1/provider/business/${businessId}/booking/${bookingId}/status/${resourceId}`, data),
  // Nuevo endpoint para confirmar clientes
  confirmBooking: (bookingId, data) => 
    api.post(`/v1/provider/business/booking/${bookingId}/confirm`, data),

  createBooking: (BId, data) =>api.post(`/v1/provider/business/${BId}/booking`, data),

  getAvailableResources: (businessId) => 
    api.get(`/v1/provider/business/${businessId}/resources/available`),
  getAllrp: (businessId, data) => api.get('/v1/provider/business/'+businessId+'/booking/rp', data),
  getAllsellerp: (businessId, data) => api.get('/v1/provider/business/'+businessId+'/booking/seller', data),
  
  // getAllTours      : (businessId, data) => api.get('/v1/provider/business/'+businessId+'/tour', data),
  // getOneTour       : (businessId, tourId) => api.get('/v1/provider/business/'+businessId+'/tour/'+tourId),
  // createTour       : (businessId, data) => api.post('/v1/provider/business/'+businessId+'/tour', data),
  // updateTour       : (businessId, tourId, data) => api.put('/v1/provider/business/'+businessId+'/tour/'+tourId, data),
  // deleteTour       : (businessId, tourId) => api.delete('/v1/provider/business/'+businessId+'/tour/'+tourId),
  // updateEnableTour : (businessId, tourId) => api.put('/v1/provider/business/'+businessId+'/tour/'+tourId+'/enable'),
  // validateTourSlug : (businessId, data) => api.post('/v1/provider/business/'+businessId+'/tour/validate-slug', data),

  // getTourBookingNotification: (businessId, tourId) => api.get(`/v1/provider/business/${businessId}/tour/${tourId}/booking-notification`),
  // createTourBookingNotification: (businessId, tourId, data) => api.post(`/v1/provider/business/${businessId}/tour/${tourId}/booking-notification`, data),
  // updateTourBookingNotification: (businessId, tourId, configNotifId, data) => api.put(`/v1/provider/business/${businessId}/tour/${tourId}/booking-notification/${configNotifId}`, data),
  // deleteTourBookingNotification: (businessId, tourId, configNotifId) => api.delete(`/v1/provider/business/${businessId}/tour/${tourId}/booking-notification/${configNotifId}`),
  
  // getSharedVacantsGroup: (businessId) => api.get(`/v1/provider/business/${businessId}/shared-vacants-group`),
  // createSharedVacantsGroup: (businessId, data) => api.post(`/v1/provider/business/${businessId}/shared-vacants-group`, data),
  // updateSharedVacantsGroup: (businessId, svgId, data) => api.put(`/v1/provider/business/${businessId}/shared-vacants-group/${svgId}`, data),
  // deleteSharedVacantsGroup: (businessId, svgId) => api.delete(`/v1/provider/business/${businessId}/shared-vacants-group/${svgId}`),

  // getTourTax: (businessId) => api.get(`/v1/provider/business/${businessId}/tour-tax`),
  // getOneTourTax: (businessId, ttId) => api.get(`/v1/provider/business/${businessId}/tour-tax/${ttId}`),
  // createTourTax: (businessId, data) => api.post(`/v1/provider/business/${businessId}/tour-tax`, data),
  // updateTourTax: (businessId, ttId, data) => api.put(`/v1/provider/business/${businessId}/tour-tax/${ttId}`, data),
  // deleteTourTax: (businessId, ttId) => api.delete(`/v1/provider/business/${businessId}/tour-tax/${ttId}`),
}

export default BookingService