// useBookingAlerts.js
import { useEffect, useRef } from 'react';
import { notification } from 'antd';  // O cualquier otra librería que uses para notificaciones
import firebaseHelper from './firebaseHelper';  // Asegúrate de importar correctamente tu helper de Firebase

const useBookingAlerts = () => {
  const lastTimestampRef = useRef(null); // Guardamos el último timestamp procesado

  useEffect(() => {
    const unsubscribe = firebaseHelper.listenToEvents((eventType, snapshot) => {
      if (eventType === 'added') {
        const newBooking = snapshot.val();
        const bookingId = snapshot.key; // Obtén el ID del ticket

        // Recupera el ID del negocio desde localStorage
        const savedBusinessId = localStorage.getItem('provider_tappit_user_business_id');

        // Verificar si el ID del negocio coincide
        if (newBooking.business_id === savedBusinessId) {
          // Verificar si el ticket ya ha sido notificado
          firebaseHelper.getTicketById(bookingId).then(ticket => {
            if (ticket && !ticket.isNotified) {  // Si no ha sido notificado
              // Mostrar la notificación
              notification.success({
                message: 'Nueva Reserva Agregada',
                description: `Se ha añadido una nueva reserva de ${newBooking.customer_name}`,
              });

              // Actualizar el estado de notificación en Firebase
              firebaseHelper.updateTicketStatus(bookingId, { isNotified: true });

              // Actualizamos el último timestamp procesado
              lastTimestampRef.current = newBooking.timestamp;
            }
          });
        }
      }
    });

    // Limpiar la suscripción cuando el componente se desmonte o se vuelva a ejecutar
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);  // Asegúrate de que solo se ejecute una vez al montar el componente

};

export default useBookingAlerts;
