import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { QuickUserToggler } from "../extras/QuiclUserToggler";

export function TopbarUser() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display", true),
    };
  }, [uiService]);

  return (
    <div
      className="topbar"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end", // Alinear hacia la derecha
        gap: "10px",
        padding: "5px",
        width: "100%", // Asegurar que ocupe el ancho necesario
        position: "relative", // Evita que interfiera con otros elementos
      }}
    >
      {layoutProps.viewUserDisplay && (
        <QuickUserToggler
          style={{
            marginLeft: "auto",
            zIndex: 2, // Eleva su prioridad para evitar interferencias
          }}
        />
      )}
    </div>
  );
}

