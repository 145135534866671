import React, { useEffect } from 'react';
import { Modal, Form, Input, Select } from 'antd';
import PropTypes from 'prop-types'
import useUserForm from './hook'

const UserForm = ({isVisible, data, onCancel, onSuccess, hidePermission}) => {

  const { form, loading, userSaved, listPermissions, onSubmit, setInitialData, afterCloseForm } = useUserForm()

  useEffect(() => {

    if(isVisible){
        setInitialData( data ? data : {} )
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  useEffect(() => {
    if(userSaved.success){
      onSuccess(userSaved.data)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSaved.success])

  return (
    <Modal
      visible={isVisible}
      title={data && data.id ? "Editar Usuario"  : "Nuevo Usuario"}
      forceRender
      okText={"Guardar"}
      cancelText={"Cancelar"}
      okButtonProps={{loading: loading}}
      onOk={onSubmit}
      onCancel={onCancel}
      zIndex={2001}
      afterClose={ afterCloseForm }
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
      >

        <Form.Item
          name="first_name"
          label={'Nombre'}
          hasFeedback
          rules={[{required: true}]}
        >
          <Input placeholder={'Juan Antonio'} type="text"></Input>
        </Form.Item>
        
        <Form.Item
          name="last_name"
          label={'Apellidos'}
          hasFeedback
          rules={[{required: true}]}
        >
          <Input placeholder={'Perez Sanchez'} type="text"></Input>
        </Form.Item>

        <Form.Item
          name="email"
          label={"Correo electrónico"}
          hasFeedback
          rules={[{required: false, type: "email"}]}
        >
          <Input placeholder={"juan@email.com"} type="email"></Input>
        </Form.Item>

        {
          !hidePermission&&<Form.Item
            name="user_permission_id"
            label={"Permiso"}
            hasFeedback
            rules={[{required: false, type: "number"}]}
          >
            <Select dropdownStyle={{zIndex: 2001}} placeholder={"Seleccione un Permiso"} allowClear>
              {
                listPermissions.map((permission, index) => (
                  <Select.Option value={parseInt(permission.id)} key={index}>{permission.name}</Select.Option>
                ))
              }
            </Select>
          </Form.Item>
        }
        
        <Form.Item
          name="username"
          label={'Nombre de usuario'}
          hasFeedback
          rules={[{required: true}]}
        >
          <Input placeholder={'juan_antonio'} type="text"></Input>
        </Form.Item>
        
        <Form.Item
          name="password"
          label={"Contraseña"}
          hasFeedback
          rules={[{required: data && data.id ? false : true}]}
        >
          <Input.Password placeholder={'**********'} />
        </Form.Item>
        
      </Form>
    </Modal>
  );
};

UserForm.propTypes = {
  t         : PropTypes.any,
  isVisible : PropTypes.bool,
  data      : PropTypes.object,
  onCancel  : PropTypes.func,
  onSuccess : PropTypes.func,
  hidePermission: PropTypes.bool
}

export default UserForm;